<template>
  <div class="bg-light px-5 py-3 font-family: 'Inter'">
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <label
              @click="back()"
              style="
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 28px;
                color: #9e9e9e;
                cursor: pointer;
              "
              ><span class="fas fa-arrow-left"></span> Kembali ke Pengawasan
              VM</label
            >
          </div>
          <!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <!-- <li class="ml-3"><button type="button" class="btn btn-block btn-outline-primary btn-lg p-3" @click="showModal"><span class="fas fa-plus"> </span>  {{ $route.params.isDetail ? "Detail" : "Edit"}}</button></li> -->
            </ol>
          </div>
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </div>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2"></div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </div>

    <!-- /.modal -->
    <div
      class="modal fade"
      id="slotModal"
      role="dialog"
      aria-labelledby="slotModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <form @submit.prevent="save" class="card">
          <div class="modal-content">
            <div class="modal-header bg-primary">
              <h5 class="modal-title" id="slotModalLabel">
                <i class="fas fa-plus"></i> Penyesuaian Stok
              </h5>
            </div>
            <div class="modal-body">
              <div class="row border" v-if="formSlot.product.id">
                <div class="col-sm-4">
                  <img
                    :src="formSlot.product.image"
                    width="100%"
                    height="100%"
                    class="img-thumbnail"
                    alt="No Image"
                  />
                </div>
                <div class="col-sm-6 align-self-center ml-2">
                  <div class="row">
                    <label>{{ formSlot.product.sap_id }}</label>
                  </div>
                  <div class="row">
                    <label>{{ formSlot.product.sku }}</label>
                  </div>
                </div>
                <div
                  class="col-sm-1 align-self-center"
                  v-on:click="clearProduct"
                >
                  <label class="text-danger">X</label>
                </div>
              </div>
              <div class="form-group" v-else>
                <label for="recipient-produk" class="col-form-label"
                  >SAP ID<span class="text-red">*</span></label
                >
                <select2
                  v-model="formSlot.product.id"
                  :config="productConfig"
                  class="form-control"
                  v-on:change="signalChange"
                >
                  <option
                    :value="formSlot.product.id"
                    selected
                    v-if="formSlot.product.id && this.$route.query.vendingId"
                  >
                    {{ formSlot.product.sap_id }}
                  </option>
                </select2>
                <span v-if="errorProduct" class="text-red">{{
                  errorProduct
                }}</span>
              </div>

              <div class="form-group">
                <label for="recipient-stock" class="col-form-label"
                  >Nama Produk<span class="text-red">*</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  id="recipient-sku"
                  v-model="formSlot.product.name"
                  :disabled="loading"
                  autocomplete="off"
                  readonly
                />
              </div>

              <div class="form-group">
                <label for="recipient-stock" class="col-form-label"
                  >Kuantitas<span class="text-red">*</span></label
                >
                <input
                  type="number"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  @change="checkNumber"
                  class="form-control"
                  id="recipient-stock"
                  v-model="formSlot.stock"
                  :disabled="loading"
                />
                <span v-if="error !== ''" class="text-red"> {{ error }}</span>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                @click="resetSlot"
              >
                Batal
              </button>
              <!-- <button type="button" class="btn btn-danger" data-dismiss="modal">Delete</button> -->
              <button type="submit" class="btn btn-primary" :disabled="loading">
                <i class="fas fa-spinner fa-spin" v-if="loading"></i>
                {{ loading ? "Simpan ..." : "Simpan" }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-4">
          <div class="box1 m-2">
            <div class="inner" v-if="formVending">
              <p class="label1">{{ formVending.name }}</p>
              <p class="label3">{{ formVending.vending_code }}</p>
              <p class="label3">{{ formVending.address }}</p>
            </div>
          </div>
        </div>
        <!-- /.col -->
        <div class="col-sm-8">
          <ol class="breadcrumb float-sm-right bg-light">
            <li class="mr-4">
              <div class="box1 m-2">
                <div class="inner" v-if="infoTransaction">
                  <p class="label4">Jumlah Transaksi</p>
                  <h3 class="mb-4">
                    {{ infoTransaction.current_month_total_trx }}
                  </h3>
                  <p
                    style="color: #c2c2c2"
                    v-if="
                      infoTransaction.current_month_total_trx >=
                      infoTransaction.previous_month_total_trx
                    "
                  >
                    <span style="color: #43936c">
                      <i class="fas fa-arrow-up"></i>
                      {{ infoTransaction.margin_percent }}%
                    </span>
                    dari bulan lalu
                  </p>
                  <p
                    style="color: #c2c2c2"
                    v-if="
                      infoTransaction.current_month_total_trx <
                      infoTransaction.previous_month_total_trx
                    "
                  >
                    <span style="color: red">
                      <i class="fas fa-arrow-down"></i>
                      {{ infoTransaction.margin_percent }}%
                    </span>
                    dari bulan lalu
                  </p>
                </div>
              </div>
            </li>
          </ol>
        </div>
        <!-- /.col -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->

    <div class="container-fluid">
      <div
        class="card"
        v-for="(row, rindex) in listSlot2"
        :key="'row-' + rindex"
      >
        <div class="row p-2">
          <div
            class="col m-2"
            v-for="(col, cindex) in row"
            :key="'col-' + cindex"
            style="text-align: center; align-items: center"
            :id="col.id"
          >
            <p class="label5" style="color: #000000">{{ col.slot_number }}</p>
            <div class="bg-light border border-light" style="width: 100%">
              <div
                :style="{
                  'background-color':
                    col.is_error === true && col.id !== null
                      ? '#CB3A31'
                      : '#f8f9fa',
                  color: '#f8f9fa',
                }"
              >
                <p><i class="fas fa-exclamation-circle"></i> Slot Error</p>
              </div>
              <img
                :src="col.product_image"
                style="width: 124px; height: 150px"
                @click="btnAddUpdate(col)"
                v-if="col.product_image"
              />
              <div v-else style="width: 124px; height: 150px; display: flex;" @click="btnAddUpdate(col)">
                <img src="Add.svg" style="" class="m-auto" />
              </div>

              <div style="width: 100%; background: #f5f5f5; bottom: 0">
                <div
                  :style="{
                    'background-color':
                      col.stock <= 3 && col.id !== null ? '#CD7B2E' : '#f8f9fa',
                    color: '#f8f9fa',
                  }"
                >
                  <p><i class="fas fa-exclamation-triangle"></i> Stok Rendah</p>
                </div>
                <p>{{ col.product_name || "Tambah\nProduk" }}</p>
                <input
                  type="text"
                  v-model="col.stock"
                  class="input-stock"
                  @blur="saveStock(col)"
                  :disabled="col.id == null"
                />
                / {{ maxSlot }}
              </div>
              <label class="switch">
                <input
                  type="checkbox"
                  v-if="col.id"
                  @click="saveActiveSlot(col)"
                  v-model="col.is_active"
                />
                <div class="slider round"></div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const { console } = window;

export default {
  components: {},
  data() {
    return {
      maxSlot: 11,
      slotList: null,
      error: "",
      errorProduct: "",
      loading: false,
      vending: null,
      count: null,
      id: null,
      vending_id: null,
      productConfig: {},
      merchantConfig: {},
      vendingTypeConfig: {},
      dataparams: {
        row: null,
        col: null,
      },
      formSlot: {
        id: null,
        name: "",
        product: {
          id: null,
          name: null,
          image: null,
          sku: null,
          sap_id: null,
        },
        vending: {
          id: null,
        },
        stock: 0,
        is_active: true,
        is_error: false,
      },
      formVending: {
        id: null,
        vending_code: "",
        name: "",
        address: "",
        row_slot: null,
        col_slot: null,
      },
      infoTransaction: {},
      listSlot: {},
      listSlot2: [],
    };
  },
  async mounted() {
    if (!this.$route.query.vendingId) return;

    this.vending_id = this.$route.query.vendingId;

    // this.dataparams.row = parseInt(this.$route.query.row)
    // this.dataparams.col = parseInt(this.$route.query.col)

    try {
      await this.getDataVending();
      await this.getData();
      await this.getInfoTransaction();

      this.initData();
      // this.getDataCount()
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    async getInfoTransaction() {
      const res = await this.Api.get(
        `/vending/info-transaction/${this.vending_id}`
      );
      this.infoTransaction = res.data.data;
    },
    initData() {
      this.productConfig = {
        ajax: {
          url: env.api.base + "/product?sort=id",
          data: (params) => {
            var filters = [["sap_id", "like", params.term]];
            var query = {
              sort: "sap_id",
              filters: params.term ? JSON.stringify(filters) : "",
              page: params.page ? params.page : 1,
            };

            return query;
          },
          processResults: (data, params) => {
            data.content.forEach((item) => {
              item.text = item.sap_id;
            });
            return {
              results: data.content,
              pagination: {
                more: data.last == false,
              },
            };
          },
        },
        placeholder: "Cari SAP ID yang tersedia"
      };
    },
    async getData() {
      const res = await this.Api.get(
        `/slot?size=100&filters=[["vending.id","=","${this.vending_id}"]]`
      );
      const data = res.data.content;
      let number = 0;

      for (let i = 0; i < this.formVending.row_slot; i++) {
        let coltemp = [];
        for (let j = 0; j < this.formVending.col_slot; j++) {
          number++;
          const idx = data.findIndex((t) => parseInt(t.name) === number);

          coltemp.push({
            id: idx > -1 ? parseInt(data[idx].id) : null,
            slot_number: number,
            product_name: idx > -1 ? data[idx].product.name : null,
            product_image: idx > -1 ? data[idx].product.image : null,
            product_sku: idx > -1 ? data[idx].product.sku : null,
            product_id: idx > -1 ? data[idx].product.id : null,
            stock: idx > -1 ? data[idx].stock : 0,
            is_error: idx > -1 ? data[idx].is_error : false,
            is_active: idx > -1 ? data[idx].is_active : false,
          });
        }
        this.listSlot2.push(coltemp);
      }
    },
    async getDataVending() {
      const res = await this.Api.get(`/vending/${this.$route.query.vendingId}`);
      this.formVending = res.data;
    },
    async getDataCount() {
      if (this.$route.query.vendingId) {
        this.Api.get(
          `/transaction/countByVending/${this.$route.query.vendingId}`
        )
          .then((res) => {
            this.count = res.data;
          })
          .catch((e) => {});
      }
    },
    signalChange() {
      this.Api.get(`/product/${this.formSlot.product.id}`)
        .then((res) => {
          this.formSlot.product = res.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    clearProduct() {
      this.formSlot.product.id = null;
      this.formSlot.product.image = null;
      this.formSlot.product.name = null;
      this.formSlot.product.sku = null;
      this.formSlot.product.sap_id = null;
    },
    showModal() {
      this.getDataVending();
      $("#exampleModal").modal("show");
    },
    updateVending() {
      this.loading = true;
      this.Api.put(`/vending/${this.formVending.id}`, this.formVending)
        .then((res) => {
          this.loading = false;
          $("#exampleModal").modal("hide");
        })
        .catch((e) => {
          this.loading = false;
          this.error = e.response.data.message;
          this.text_validation = "Submit Failed";
          this.message();
        });
    },
    formatPrice(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    resetSlot(value) {
      this.error = "";
      this.errorProduct = "";
      this.formSlot = {
        id: null,
        name: value?.toString(),
        product: {
          id: null,
          name: null,
          image: null,
          sku: null,
          description: null,
          sap_id: null,
        },
        vending: {
          id: 1,
        },
        stock: 0,
        is_active: true,
        is_error: false,
      };
    },
    async btnAddUpdate(value) {
      this.resetSlot(value.slot_number);

      try {
        if (value?.id) {
          const res = await this.Api.get("/slot/" + value?.id);
          this.formSlot = res.data;
        }
        $("#slotModal").modal("show");
      } catch (error) {
        console.log(error);
      }
    },
    checkNumber(e) {
      if (e.target.value >= this.maxSlot) {
        this.error = `Batas maksimal kuantitas produk adalah ${this.maxSlot}`;
      } else if (this.formSlot.stock === "" && this.formSlot.product) {
        this.error = "Kuantitas wajib diisi";
      } else {
        this.error = "";
      }
    },
    async save() {
      if (
        this.formSlot.stock !== "" &&
        this.formSlot.stock <= this.maxSlot &&
        this.formSlot.product.id !== null
      ) {
        try {
          let res;

          if (this.formSlot.id !== null) {
            await this.Api.put(`/slot/${this.formSlot.id}`, this.formSlot);
            this.$toast.success("Data berhasil diubah.", {
              position: "bottom-right",
              timeout: 3000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
          } else {
            res = await this.Api.post("/slot", this.formSlot);
            this.$toast.success("Data berhasil ditambah.", {
              position: "bottom-right",
              timeout: 3000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
          }

          for (let i = 0; i < this.formVending.row_slot; i++) {
            const idx = this.listSlot2[i].findIndex(
              (t) => t.slot_number === parseInt(this.formSlot.name)
            );
            if (idx > -1) {
              this.listSlot2[i][idx] = {
                id: this.formSlot.id !== null ? this.formSlot.id : res.data.id,
                product_name: this.formSlot.product.name,
                product_id: this.formSlot.product.id,
                product_image: this.formSlot.product.image,
                product_sku: this.formSlot.product.sku,
                stock: parseInt(this.formSlot.stock),
                slot_number: parseInt(this.formSlot.name),
                is_active: this.formSlot.is_active,
                is_error: this.formSlot.is_error,
              };
            }
          }

          this.resetSlot();
          $("#slotModal").modal("hide");
        } catch (error) {
          this.error = error.response.data.message;
          console.log(error);
          // alert('Submit Failed')
        }
      } else if (this.formSlot.product.id === null && this.formSlot.id) {
        await this.Api.delete(`/slot/${this.formSlot.id}`);
        this.$toast.success("Data berhasil dihapus.", {
          position: "bottom-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        $("#slotModal").modal("hide");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        if (this.formSlot.product.id === null) {
          this.errorProduct = "SAP ID wajib diisi.";
        }
        if (this.formSlot.stock === "") {
          this.error = "Kuantitas wajib diisi.";
        } else if (this.formSlot.stock >= this.maxSlot) {
          this.error = `Batas maksimal kuantitas produk adalah ${this.maxSlot}`;
        }
      }
    },
    back() {
      this.$router.replace({ path: "PengawasanVM" });
    },
    async saveStock(col) {
      if (col.stock > this.maxSlot) {
        return this.$toast.error("Melebihi batas maksimal.", {
          position: "bottom-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      } else if (col.stock === "") {
        return this.$toast.error("Kuantitas wajib diisi.", {
          position: "bottom-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      } else {
        try {
          await this.Api.put(`/slot/${col.id}`, {
            id: col.id,
            stock: col.stock,
            name: col.slot_number,
            product: {
              id: col.product_id,
              name: col.product_name,
              sku: col.product_sku,
              image: col.image,
            },
            vending: {
              id: this.vending_id,
            },
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          this.$toast.success("Data berhasil diubah.", {
            position: "bottom-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        } catch (error) {
          this.text_validation = "Gagal menyimpan";
          alert(error);
        }
      }
    },
    async saveActiveSlot(col) {
      col.is_active = !col.is_active;
      this.$emit("setCheckboxVal", this.formSlot.is_active);

      try {
        await this.Api.put(`/slot/change-status/${col.id}`, {
          // id: col.id,
          //   stock: col.stock,
          //   name: col.slot_number,
          //   price: col.price,
          is_active: col.is_active,
          // product: {
          //   id: col.product_id,
          //   name: col.product_name,
          //   sku: col.product_sku,
          //   image: col.image
          // },
          // vending: {
          //   id: this.vending_id
          // }
        });
        this.$toast.success("Status berhasil diubah.", {
          position: "bottom-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      } catch (error) {
        this.$toast.error(error.response.data.message, {
          position: "bottom-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },
  },
};
</script>
<style>
.box1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 12px 24px;
  gap: 8px;
  width: 360px;
  height: 190px;
  left: 64px;
  top: 132px;
  background: #ffffff;
  border-radius: 12px;
}
.label1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #0a0a0a;
}
.label2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #404040;
}
.label3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #404040;
}
.label4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #32434a;
}
.balel5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: #000000;
}
.borderDiv {
  border: 1px solid #c2c2c2;
}
.notActive {
  color: red;
}
</style>
